/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDPiXENVVnqo4iOAXde-9phxQ_k0tB7XcU",
  "appId": "1:376349954453:web:e9d94746efa7d2e4b2e69c",
  "authDomain": "schooldog-i-florencecity-al.firebaseapp.com",
  "measurementId": "G-F9M4PX3776",
  "messagingSenderId": "376349954453",
  "project": "schooldog-i-florencecity-al",
  "projectId": "schooldog-i-florencecity-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-florencecity-al.appspot.com"
}
